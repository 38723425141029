import * as Sentry from "@sentry/vue";

export default function initializeSentry(app) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    app,
    dsn: "https://de83f11275fbd99560e0f0a0596ef43b@o4506862524497920.ingest.us.sentry.io/4507062005858304",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    ignoreErrors: [
      "Cross-origin script load denied by Cross-Origin Resource Sharing policy.",
      "(unknown): Script error.",
      "Cannot read properties of null (reading 'addedIndex')",
      "null is not an object (evaluating 'd.addedIndex')", // this and above 'addedIndex' error is from smooth-dnd (Tested it and it is not breaking anything in the UI)
      "Cannot redefine property: googletag",
      "An invalid or illegal string was specified", // gtm error
    ],
    beforeSend(event, hint) {
      const errorMessagesToIgnore = [
        "Failed to fetch dynamically imported module",
        "error loading dynamically imported module",
      ];
      const errorMessage = hint.originalException?.message;
      if (
        errorMessage &&
        errorMessagesToIgnore.some((message) => errorMessage.includes(message))
      ) {
        // Return null to prevent the error from being sent to Sentry
        return null;
      }

      // Send the error
      return event;
    },
    environment: "production",
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
