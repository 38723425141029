import axios from "axios";

function get(url, data = {}) {
  return axios.get(url, { params: data });
}

// requires csrf token
function post(url, data = {}, params = {}) {
  return instance().post(url, data, {
    params,
  });
}

function destroy(url, data = {}) {
  return instance().delete(url, { params: data });
}

// required csrf token
function put(url, data = {}) {
  return instance().put(url, data);
}

function patch(url, data = {}) {
  return instance().patch(url, data);
}

// Private Methods
function instance() {
  return axios.create({
    headers: {
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.getAttribute("content"),
    },
    "Content-Type": "application/json",
    responseType: "json",
  });
}

export { post, get, destroy, put, patch };
